<div *ngIf="userGroup" class="center-container flex flex-col gap-8 items-center">
    <h2>Ustawienia grupy</h2>
    <div>
        <h5 class="w-full text-center">Nazwa</h5>
        <div class="flex flex-col gap-2">
            <input type="text" class="choiry-input" (input)="validationService.updateName('usergroup-name-input');" id="usergroup-name-input" value="{{userGroup.name}}"/>
            <!-- <div *ngIf="titleValidity!=''" class="flex gap-2 h-6 text-error-content items-center">
                <span class="icon-24 w-6 h-6">warning</span>
                <p class="font-label" [innerHTML]="titleValidity"></p>
            </div> -->
            <!-- TODO REMOVE BELLOW -->
            <div id="validation-feedback-usergroup-name-input" class="ch-validation-feedback text-error-content"></div>
        </div>
    </div>
    <div class="max-w-[768px] w-full">
        <h5 class="w-full text-center">Opis</h5>
        <div class="flex flex-col gap-2 w-full">
            <textarea class="w-full h-48 bg-ch-blue-50 text-ch-grey-900 p-4 rounded-lg" (input)="validationService.updateDescription('usergroup-description-input');" type="text" id="usergroup-description-input">{{userGroup.description}}</textarea>
            <div id="validation-feedback-usergroup-description-input" class="ch-validation-feedback text-error-content"></div>
        </div>
    </div>
    <div class="flex gap-4">
        <div class="ch-btn ch-btn-outline ch-btn-sec" [routerLink]="['/usergroup',userGroup.id]">Anuluj</div>
        <div class="ch-btn ch-btn-filled ch-btn-sec" (click)="updateUserGroup()">Zapisz</div>
    </div>
    <div class="w-full flex gap-4">
        <div class="flex-1 p-2">
            <h4>Użytkownicy w grupie</h4>
            <ul class="ch-list min-h-[12]" cdkDropList id="usergroup-users" [cdkDropListConnectedTo]="['all-users']" [cdkDropListData]="userGroup.users" (cdkDropListDropped)="addMemberToUserGroup($event)">
                <li *ngFor="let user of userGroup.users" class="text-left ch-list-item flex cursor-move" [cdkDragData]="user" cdkDrag>
                    <div class="w-12 h-12 m-2 flex-none" ><avatar type="user-avatar" [avatarUrl]="user.avatarUrl" [userId]="user.id" [firstName]="user.firstName" [lastName]="user.lastName"></avatar></div> 
                    <div class="flex-1 py-4"><strong>{{user.firstName}} {{user.lastName}}</strong> - {{user.username}}</div>
                </li>
            </ul>
        </div>
        <div class="flex-1 p-2">
            <h4 class="inline-block mr-12">Wyszukaj użytkowników</h4>
            <div class="w-full flex flex-col gap-4">
                <input type="text" class="choiry-input w-48" placeholder="Search..."  id="searchuser" (input)="updateSearchResult()"/>

                <ul class="ch-list min-h-[12]" cdkDropList id="all-users" [cdkDropListConnectedTo]="['usergroup-users']" [cdkDropListData]="users" (cdkDropListDropped)="removeMemberFromUserGroup($event)">
                    <li *ngFor="let user of users" class="ch-list-item text-left flex cursor-move" [cdkDragData]="user" cdkDrag>
                        <div class="w-12 h-12 m-2 flex-none" ><avatar type="user-avatar" [avatarUrl]="user.avatarUrl" [userId]="user.id" [firstName]="user.firstName" [lastName]="user.lastName"></avatar></div> 
                            <div class="flex-1 py-4"><strong>{{user.firstName}} {{user.lastName}}</strong> - {{user.username}}</div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
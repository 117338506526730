import { Component, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Piece, Playlist } from '@app/_models';
import { PieceService, PlaylistService, AlertService } from '@app/_services';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { ValidationService } from '@app/_services/validation.service';

@Component({ templateUrl: 'editplaylist.component.html' })
export class EditPlaylistComponent implements OnInit {
    id: number;
    pieces: Piece[];
    playlist: Playlist;
    sub: any;

    constructor(
        private route: ActivatedRoute,
        private pieceService: PieceService,
        private playlistService: PlaylistService,
        private alertService: AlertService,
        public validationService: ValidationService
    ) {  }


    ngOnInit() {
        this.sub=this.route.params.subscribe(params => {
            this.id = +params['id']; // (+) converts string 'id' to a number
            this.playlistService.getById(this.id,undefined).subscribe(x => { this.playlist = x; if(this.pieces)this.subtractSets()});
            this.pieceService.getUserOwnedAll().subscribe(x => { this.pieces = x; if(this.playlist)this.subtractSets()});
          })
    }

    subtractSets(){
        if(this.pieces.length>0 && this.playlist.pieces.length>0){
            this.pieces = this.pieces.filter(x => {var dup=0; this.playlist.pieces.forEach(y => {if(x.id==y.id) dup+=1;}); return dup==0;})
        }
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
      }
    
    updatePlaylist(){
        var update = new Playlist;
        update.id = this.playlist.id;
        update.description=(<HTMLInputElement>document.getElementById("playlist-description-input")).value;
        update.title=(<HTMLInputElement> document.getElementById("playlist-title-input")).value;
        if(this.validationService.validateName(update.title)&&this.validationService.validateDescription(update.description)){
            this.playlistService.update(update).subscribe(() => this.alertService.success("Playlist title and description updated"));
        } else {
            this.alertService.error("Check validity of input data!");
        }
    }

    sortedPlaylistPieces(){
        return this.playlist.pieces.sort((a, b) => a.ordinalNumber > b.ordinalNumber ? 1 : a.ordinalNumber === b.ordinalNumber ? 0 : -1);
      }

      //index is ordinal number minus one
    addPieceToPlaylist(event: CdkDragDrop<Piece[]>){
        if (event.previousContainer === event.container) {
            this.playlistService.reorderPiece(this.playlist.id,event.item.data.id,event.currentIndex+1).subscribe(()=>{
                moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
                if(event.currentIndex < event.previousIndex) {
                    this.playlist.pieces.filter((p) => (p.ordinalNumber < (event.previousIndex+1) && p.ordinalNumber >= (event.currentIndex+1))).forEach((p)=> p.ordinalNumber+=1);
                } else {
                    this.playlist.pieces.filter((p) => (p.ordinalNumber > (event.previousIndex+1) && p.ordinalNumber <= (event.currentIndex+1))).forEach((p)=> p.ordinalNumber-=1);
                }
                this.playlist.pieces.find((p)=> p.id==event.item.data.id).ordinalNumber=event.currentIndex+1;
            });
        } else {
            this.playlistService.addPiece(this.playlist.id,event.item.data.id,event.currentIndex+1).subscribe(()=> {
                transferArrayItem(event.previousContainer.data,event.container.data,event.previousIndex,event.currentIndex);
                this.playlist.pieces.filter((p)=> p.ordinalNumber>=event.currentIndex+1).forEach((p)=>p.ordinalNumber+=1);
                this.playlist.pieces.find((p)=> p.id==event.item.data.id).ordinalNumber=event.currentIndex+1;
            });
        }
    }
    removePieceFromPlaylist(event: CdkDragDrop<Piece[]>){
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(event.previousContainer.data,event.container.data,event.previousIndex,event.currentIndex);
            this.playlist.pieces.filter((p)=> p.ordinalNumber>(event.previousIndex+1)).forEach((p)=>p.ordinalNumber-=1);
            this.playlistService.removePiece(this.playlist.id,event.item.data.id).subscribe();
        }
    }

}
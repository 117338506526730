<div class="w-full flex gap-6">
  <app-menu></app-menu>
  <div class="flex-1">
    <div class="bg-white pb-[56px] flex flex-col gap-12 rounded-b-xl">
      <div class="h-16 bg-ch-grey-50 rounded-t-xl w-full flex py-4 px-8 items-center justify-between">
        <div class="rounded-full w-8 h-8 border-ch-grey-700 border-[1px] p-1"><div class="icon icon-24">arrow_left_alt</div></div>
        <div class="font-boldbody">Udostępnienia</div>
        <div class="w-8 h-8"></div>
      </div>
      <div class="flex flex-col gap-4 px-12">
        <div>
          <app-dropdownfilter [options]="[{key:'all', name:'Wszystko'},{key:'playlists',name:'Udostępnione Playlisty'},{key:'pieces',name:'Udostępnione utwory'}]"  (onSelect)="setFilter($event)"></app-dropdownfilter>
        </div>
          <div *ngIf="pieceShares && (filter === 'pieces' || filter === 'all')" >
            <div *ngFor="let pieceShare of pieceShares" class="flex justify-between items-center p-4 cursor-pointer hover:bg-ch-blue-50">
              <div class="flex gap-3 items-center">
                <div *ngIf="pieceShare.shareLinkEnabled " class="icon-24 text-primary">link</div>
                <div *ngIf="pieceShare.userId != null " class="icon-24 text-primary">person</div>
                <div *ngIf="pieceShare.userGroupId != null " class="icon-24 text-primary">group</div>
                <div *ngIf="pieceShare.shareLinkEnabled " class="flex gap-2 items-center">
                  <a href="{{sharingPieceLink}}{{pieceShare.objectId}}/{{pieceShare.linkKey}}">{{sharingPieceLink}}{{pieceShare.objectId}}/{{pieceShare.linkKey}}</a>
                  <i class="icon-btn icon-24" (click)="copyToClipboard(sharingPieceLink+pieceShare.objectId+'/'+pieceShare.linkKey)">content_copy</i>
                </div>
                <div *ngIf="pieceShare.userId != null " class="flex gap-2 items-center" [routerLink]="['/piece',pieceShare.objectId]">
                    <avatar type="user-avatar" [avatarUrl]="pieceShare.user.avatarUrl" [userId]="pieceShare.userId" [firstName]="pieceShare.user.firstName" [lastName]="pieceShare.user.lastName"></avatar>
                    <div class="font-boldbody">{{pieceShare.user.firstName}} {{pieceShare.user.lastName}}</div>
                </div>
                <div *ngIf="pieceShare.userGroupId != null " class="flex gap-2 items-center" [routerLink]="['/piece',pieceShare.objectId]">
                  <avatar type="usergroup-avatar" [userGroup]="pieceShare.userGroup"></avatar>
                  <div class="font-boldbody">{{pieceShare.userGroup.name}}</div>
                </div>
              </div>
              <div class="icon icon-btn icon-24 text-primary" (click)="deletePieceShare(pieceShare.id)">delete</div>
            </div>
          </div>

          <div *ngIf="playlistShares && (filter === 'playlists' || filter === 'all')" >
            <div *ngFor="let playlistShare of playlistShares" class="flex justify-between items-center p-4 cursor-pointer hover:bg-ch-blue-50">
              <div class="flex gap-3 items-center">
                <div *ngIf="playlistShare.shareLinkEnabled " class="icon-24 text-primary">link</div>
                <div *ngIf="playlistShare.userId != null " class="icon-24 text-primary">person</div>
                <div *ngIf="playlistShare.userGroupId != null " class="icon-24 text-primary">group</div>
                <div *ngIf="playlistShare.shareLinkEnabled " class="flex gap-2 items-center">
                  <a href="{{sharingPlaylistLink}}{{playlistShare.objectId}}/{{playlistShare.linkKey}}">{{sharingPlaylistLink}}{{playlistShare.objectId}}/{{playlistShare.linkKey}}</a>
                  <i class="icon-btn icon-24" (click)="copyToClipboard(sharingPlaylistLink+playlistShare.objectId+'/'+playlistShare.linkKey)">content_copy</i>
                </div>
                <div *ngIf="playlistShare.userId != null " class="flex gap-2 items-center" [routerLink]="['/playlist',playlistShare.objectId]">
                    <avatar type="user-avatar" [avatarUrl]="playlistShare.user.avatarUrl" [userId]="playlistShare.userId" [firstName]="playlistShare.user.firstName" [lastName]="playlistShare.user.lastName"></avatar>
                    <div class="font-boldbody">{{playlistShare.user.firstName}} {{playlistShare.user.lastName}}</div>
                </div>
                <div *ngIf="playlistShare.userGroupId != null " class="flex gap-2 items-center" [routerLink]="['/playlist',playlistShare.objectId]">
                  <avatar type="usergroup-avatar" [userGroup]="playlistShare.userGroup"></avatar>
                  <div class="font-boldbody">{{playlistShare.userGroup.name}}</div>
                </div>
              </div>
              <div class="icon icon-btn icon-24 text-primary" (click)="deletePlaylistShare(playlistShare.id)">delete</div>
            </div>
          </div>
      </div>
    </div>
  </div>
</div>
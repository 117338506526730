import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PieceService, PlaylistService, SharingService, UserGroupService } from '@app/_services';
import { Piece, Playlist, User, UserGroup } from '@app/_models';
import { Account } from '@app/authserver/_models';
import { AccountService } from '@app/authserver/_services';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-usergrdoup',
  templateUrl: './usergroup.component.html'
})
export class UserGroupComponent implements OnInit {
    userAccount: Account;
    id: number;
    isEditor: boolean = false;
    userGroup: UserGroup;
    creationDateString: string = '';
    playlists: Playlist[];
    pieces: Piece[];
    showUsers: boolean = true;
    showPlaylists: boolean = false;
    showPieces: boolean = false;
    invitedUsers: User[] = [];

    constructor(private route: ActivatedRoute,
      private userGroupService: UserGroupService,
      private accountService: AccountService,
      private pieceService: PieceService,
      private playlistService: PlaylistService,
      private titleService: Title
      ) {
        this.accountService.account.subscribe(x => this.userAccount = x);
        }

    ngOnInit() {
        this.route.params.subscribe(params => {
        this.id = +params['id']; // (+) converts string 'id' to a number

        //load the details
        this.userGroupService.getById(this.id).subscribe(x => { 
          this.userGroup = x; 
          this.isEditor = this.userAccount.userId==this.userGroup.ownerId;
          if(this.isEditor)
            this.userGroupService.getInvitations(this.id).subscribe((i)=>{this.invitedUsers=i;})
          this.creationDateString=new Date(x.creationDate).toLocaleTimeString() + " " + new Date(x.creationDate).toLocaleDateString();
          this.titleService.setTitle(this.userGroup.name);
        });
        this.pieceService.getUserGroupSharedAll(this.id).subscribe(x => { 
          this.pieces = x; 
        });
        this.playlistService.getUserGroupSharedAll(this.id).subscribe(x => { 
          this.playlists = x; 
        });

      })
    }
    selectTab(tab){
      this.showUsers=false;
      this.showPlaylists=false;
      this.showPieces=false
      if(tab==='users'){
        this.showUsers=true;
      } else if (tab==='playlists'){
        this.showPlaylists=true;
      } else if (tab==='pieces'){
        this.showPieces=true;
      }
    }
    revokeInvitation(userId: string){
      this.userGroupService.revokeInvitation(this.id,userId).subscribe(()=>{
        this.invitedUsers.forEach((u,index)=>{
          if(u.id===userId) {
            this.invitedUsers.splice(index,1);
          }
        })
      })
    }
    removeMemberFromUserGroup(userId: string){
      this.userGroupService.removeUser(this.userGroup.id,userId).subscribe(()=>{
        this.userGroup.users.forEach((u,index)=>{
          if(u.id===userId) {
            this.userGroup.users.splice(index,1);
          }
        })
      });
  }

}

<div *ngIf="playlist" class="center-container flex flex-col gap-8 items-center">
    <h2>Edycja playlisty</h2>
    <div>
        <h5 class="w-full text-center">Tytuł</h5>
        <div class="flex flex-col gap-2">
            <input type="text" class="choiry-input" (input)="validationService.updateName('playlist-title-input');" id="playlist-title-input" value="{{playlist.title}}"/>
            <!-- <div *ngIf="titleValidity!=''" class="flex gap-2 h-6 text-error-content items-center">
                <span class="icon-24 w-6 h-6">warning</span>
                <p class="font-label" [innerHTML]="titleValidity"></p>
            </div> -->
            <!-- TODO REMOVE BELLOW -->
            <div id="validation-feedback-playlist-title-input" class="ch-validation-feedback text-error-content"></div>
        </div>
    </div>
    <div class="max-w-[768px] w-full">
        <h5 class="w-full text-center">Opis</h5>
        <div class="flex flex-col gap-2 w-full">
            <textarea class="w-full h-48 bg-ch-blue-50 text-ch-grey-900 p-4 rounded-lg" (input)="validationService.updateDescription('playlist-description-input');" type="text" id="playlist-description-input">{{playlist.description}}</textarea>
            <div id="validation-feedback-playlist-description-input" class="ch-validation-feedback text-error-content"></div>
        </div>
    </div>
    <div class="flex gap-4">
        <div class="ch-btn ch-btn-outline ch-btn-sec" [routerLink]="['/playlist',playlist.id]">Anuluj</div>
        <div class="ch-btn ch-btn-filled ch-btn-sec" (click)="updatePlaylist()">Zapisz</div>
    </div>
    <div class="w-full flex gap-4">
        <div class="flex-1 p-2">
            <h4>Utwory na playliście</h4>
            <ul class="ch-list"  cdkDropList id="playlist-pieces" [cdkDropListConnectedTo]="['all-pieces']" [cdkDropListData]="playlist.pieces" (cdkDropListDropped)="addPieceToPlaylist($event)">
                <li *ngFor="let piece of sortedPlaylistPieces()" class="ch-list-item flex items-center p-2 cursor-move" [cdkDragData]="piece" cdkDrag>
                    <div class="flex-1"><strong>{{piece.ordinalNumber}}. {{piece.title}}</strong></div>
                    <i class="icon-24 icon-btn" [routerLink]="['/piece',piece.id]">link</i>
                </li>
            </ul>
        </div>
        <div class="flex-1 p-2">
            <h4>Wszystkie dostępne utwory</h4>
            <ul class="ch-list min-h-[16]" cdkDropList id="all-pieces" [cdkDropListConnectedTo]="['playlist-pieces']" [cdkDropListData]="pieces" (cdkDropListDropped)="removePieceFromPlaylist($event)">
                <li *ngFor="let piece of pieces" class="ch-list-item flex items-center p-2 cursor-move" [cdkDragData]="piece" cdkDrag>
                    <div class="flex-1"><strong>{{piece.title}}</strong></div>
                    <i class="icon-24 icon-btn" [routerLink]="['/piece',piece.id]">link</i>
                </li>
            </ul>
        </div>
    </div>
</div>
<div class="flex gap-6">
  <app-menu *ngIf="userAccount"></app-menu>
  <div class="flex-1 overflow-hidden">
    <div class="bg-white pb-[56px] flex flex-col gap-6 rounded-b-xl">
      <div class="h-16 bg-ch-grey-50 rounded-t-xl w-full flex py-4 px-8 items-center justify-between">
        <div class="rounded-full w-8 h-8 border-ch-grey-700 border-[1px] p-1"><div class="icon icon-24">arrow_left_alt</div></div>
        <div class="font-boldbody">Playlisty</div>
        <div class="w-8 h-8"></div>
      </div>
      <div *ngIf="cantLoad" class="px-12 font-title">Zasób do którego się odwołujesz nie istnieje lub nie masz uprawnień by go załadować.</div>
      <div *ngIf="playlist" class="">
        <div class="flex flex-col gap-4 px-12 min-w-0">
          <!-- HEADER -->
          <div class="flex justify-between px-6 py-4">
            <div class="flex items-center gap-4">
              <div class="icon icon-24 text-primary">playlist_play</div>
              <div class="font-title">{{playlist.title}}</div>
            </div>
            <div class="flex items-center gap-4">
              <avatar type="user-avatar" [size]="'small'"[avatarUrl]="playlist.owner.avatarUrl" [userId]="playlist.ownerId" [firstName]="playlist.owner.firstName" [lastName]="playlist.owner.lastName" [showCreationTooltip]="true" [tooltipDate]="playlist.creationDate"></avatar>
              <div *ngIf="isEditor" class="icon icon-btn icon-24 text-primary" [routerLink]="['/edit/playlist',playlist.id]">border_color</div>
              <div *ngIf="isEditor" (click)="openModal('playlist-sharing');" class="icon icon-btn icon-24 text-primary">share</div>
            </div>
          </div>
          <div class="w-full"><p style="white-space: pre-wrap;">{{playlist.description}}</p></div>
          <table class="w-full">
            <tr class="bg-ch-blue-50 h-12 text-ch-grey-900 font-boldbody">
              <td class="pl-6 w-16">#</td>
              <td>Tytuł</td>
              <td>Kompozytor</td>
              <td class="text-center">Data dodania</td>
              <td class="text-center pr-6"><div class="icon icon-24">timer</div></td>
            </tr>
            <!-- FULL LIST -->
            <ng-container *ngIf="showList"> 
              <tr *ngFor="let piece of sortedPlaylistPieces()" class="h-[72px] cursor-pointer" [ngClass]="piece.id==selectedPiece ? 'bg-ch-yellow-50' : 'hover:bg-ch-blue-50'" (click)="selectPiece(piece.id,piece.title)">
                <td class="pl-6">{{piece.ordinalNumber}}.</td>
                <td>
                  <div class=" flex items-center gap-3">
                    <div class="icon icon-24 text-primary">library_music</div>
                    <div>{{piece.title}}</div>
                  </div>
                </td>
                <td>-</td>
                <td class="text-center">{{readableDate(piece.creationDate)}}</td>
                <td class="pr-6 text-center">{{piece.id}}</td>
              </tr>
              <tr *ngIf="selectedPiece!=0" class="p-6 text-center cursor-pointer hover:bg-ch-blue-100" (click)="showList=false"><td colspan="5"><span class="icon-24">expand_less</span></td></tr>
            </ng-container>
            <!-- SELECTED PIECE -->
            <tr *ngIf="selectedPiece!=0 && !showList" class="p-6 flex gap-3 bg-ch-yellow-50 items-center" (click)="selectPiece(0,'')">
              <td class="pl-6"><div class="icon icon-24 text-primary">play_circle</div></td>
                <td>
                  <div class="flex items-center gap-3">
                    <div class="icon icon-24 text-primary">library_music</div>
                    <div>{{selectedPieceTitle}}</div>
                  </div>
                </td>
                <td>-</td>
                <td class="text-center">------</td>
                <td class="pr-6 text-center">----</td>
            </tr>
            <tr *ngIf="selectedPiece!=0 && !showList" class="p-6 text-center cursor-pointer hover:bg-ch-blue-100" (click)="showList=true"><td colspan="5"><span class="icon-24">expand_more</span></td></tr>
          </table>
        </div>
        <div *ngFor="let piece of playlist.pieces">
          <app-player *ngIf="selectedPiece==piece.id" [paramId]="selectedPiece" [paramLinkKey]="linkKey" [paramEmbedded]="true"></app-player>
        </div>
        <sharing-modal *ngIf="isEditor" id="playlist-sharing" objectId="{{playlist.id}}" type="playlist" title="Udostępnij playlistę" [isPublic]="playlist.sharePublicEnabled" (onPublicShare)="playlist.sharePublicEnabled = $event; updatePlaylist();"></sharing-modal>
      </div>
    </div>
  </div>
</div>
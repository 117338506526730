import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { AlertService } from '@app/_services';
import { FileUpload } from '@app/_models';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { AccountService } from '@app/authserver/_services';

@Injectable({providedIn: 'root'})
export class FileUploadService {
  
    constructor(
      private http: HttpClient,
      private alertService: AlertService,
      private accountService: AccountService
      ) { }

    uploadFile(fileUpload: FileUpload,endpoint: string,file: File,callback: (id:any)=>void){
      fileUpload.file = file;
      fileUpload.callback = callback;

      if (fileUpload.file) {
          fileUpload.inProgress = true;
          this.upload(fileUpload.file,endpoint).subscribe(
          (event: any) => {
            if (event.type === HttpEventType.UploadProgress) {
              fileUpload.progress = Math.round(100 * event.loaded / event.total);
            } else if (event instanceof HttpResponse) {
              fileUpload.inProgress =false;
              fileUpload.done = true;
              fileUpload.fileId = event.body.id;
              this.accountService.getSelfAccountFromApi().subscribe();
              fileUpload.callback(fileUpload.fileId);
            }
          },
          (err: any) => {
            console.log(err);
            if (err.error) {
              this.alertService.error('Could not upload the file!');
            }
          });
      }
  }
  
    upload(file: File,endpoint: string): Observable<HttpEvent<any>> {
      const formData: FormData = new FormData();
  
      formData.append('file', file);
  
      const req = new HttpRequest('POST', endpoint, formData, {
        reportProgress: true,
        responseType: 'json'
      });
  
      return this.http.request(req);
    }
    
    humanFileSize(bytes, si=false, dp=1) {
      const thresh = si ? 1000 : 1024;
    
      if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
      }
    
      const units = si 
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
      let u = -1;
      const r = 10**dp;
    
      do {
        bytes /= thresh;
        ++u;
      } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
    
    
      return bytes.toFixed(dp) + ' ' + units[u];
    }
  }
<div class="center-container">
    <div class="relative mx-auto w-full md:max-w-[612px] rounded-xl px-[24px] py-[32px] md:px-[106px] md:py-[64px] flex flex-col gap-[96px] items-center overflow-hidden" style="box-shadow: 0px 30px 60px 0px rgba(114, 116, 212, 0.08);">
        <svg class="absolute z-[-5] left-[11px] top-[203px] md:left-[149px] md:top-[247px] w-[530px] h-[420px] md:w-[530px] md:h-[454px]" viewBox="0 0 612 491" fill="none" xmlns="http://www.w3.org/2000/svg">
            <use href="/assets/images/plain_svg/symbol.svg#symbol"/>
        </svg>
        
        <div class="flex flex-col gap-[40px] md:gap-[56px] items-center">
            <h1>Zakup subskrypcji</h1>
            <!--add a roadmap-->
            <h4>Wybierz usługę</h4>

            <div class="py-4">
                <div class="choiry-v-radio-l" [ngClass]="productId==='SUBSCRIPTION_CHOIRY_30' ? 'choiry-v-radio-active' : 'choiry-v-radio'" (click)="selectProduct('SUBSCRIPTION_CHOIRY_30');">Subskrypcja na 30 dni</div>
                <div class="choiry-v-radio-r" [ngClass]="productId==='SUBSCRIPTION_CHOIRY_90' ? 'choiry-v-radio-active' : 'choiry-v-radio'" (click)="selectProduct('SUBSCRIPTION_CHOIRY_90');">Subskrypcja na 90 dni</div>
            </div>
            <div class="font-bold" [innerHTML]="amount"></div>

            <div class="py-4">
                <div class="choiry-v-radio-l" [ngClass]="entity==='NATURALPERSON' ? 'choiry-v-radio-active' : 'choiry-v-radio'" (click)="selectEntity('NATURALPERSON');">Osoba fizyczna</div>
                <div class="choiry-v-radio-r" [ngClass]="entity==='COMPANY' ? 'choiry-v-radio-active' : 'choiry-v-radio'" (click)="selectEntity('COMPANY');">Firma</div>
            </div>

            <div *ngIf="entity==='NATURALPERSON'" class="flex flex-col gap-10 items-center">

                <div class="flex flex-col gap-2">
                    <input type="text" id="purchase-first-name-input" class="choiry-input" #firstname placeholder="Imię" 
                        (input)="firstNameValidity=validationService.validateFirstName(firstname.value,true)" />
                    <div *ngIf="firstNameValidity!=''" class="flex gap-2 h-6 text-error-content items-center">
                        <span class="icon-24 w-6 h-6">warning</span>
                        <p class="font-label" [innerHTML]="firstNameValidity"></p>
                    </div>
                </div>

                <div class="flex flex-col gap-2">
                    <input type="text" id="purchase-last-name-input" class="choiry-input" #lastname placeholder="Nazwisko" 
                        (input)="lastNameValidity=validationService.validateLastName(lastname.value,true)" />
                    <div *ngIf="lastNameValidity!=''" class="flex gap-2 h-6 text-error-content items-center">
                        <span class="icon-24 w-6 h-6">warning</span>
                        <p class="font-label" [innerHTML]="lastNameValidity"></p>
                    </div>
                </div>
            </div>

            <div *ngIf="entity==='COMPANY'" class="flex flex-col gap-10 items-center">

                <div class="flex flex-col gap-2">
                    <input type="text" id="purchase-company-name-input" class="choiry-input" #companyname placeholder="Nazwa Firmy" 
                        (input)="companyNameValidity=validationService.validateCompanyName(companyname.value,true)" />
                    <div *ngIf="companyNameValidity!=''" class="flex gap-2 h-6 text-error-content items-center">
                        <span class="icon-24 w-6 h-6">warning</span>
                        <p class="font-label" [innerHTML]="companyNameValidity"></p>
                    </div>
                </div>

                <div class="flex flex-col gap-2">
                    <input type="text" id="purchase-taxid-input" class="choiry-input" #taxid placeholder="NIP" 
                        (input)="taxIdValidity=validationService.validateTaxId(taxid.value,true)" />
                    <div *ngIf="taxIdValidity!=''" class="flex gap-2 h-6 text-error-content items-center">
                        <span class="icon-24 w-6 h-6">warning</span>
                        <p class="font-label" [innerHTML]="taxIdValidity"></p>
                    </div>
                </div>
            </div>

            <div class="flex flex-col gap-10 items-center">
                <div class="flex flex-col gap-2">
                    <input type="text" id="purchase-email-input" class="choiry-input" #email required placeholder="E-mail" 
                        (input)="emailValidity=validationService.validateEmail(email.value,true)" />
                    <div *ngIf="emailValidity!=''" class="flex gap-2 h-6 text-error-content items-center">
                        <span class="icon-24 w-6 h-6">warning</span>
                        <p class="font-label" [innerHTML]="emailValidity"></p>
                    </div>
                </div>

                <div class="flex flex-col gap-2">
                    <input type="text" id="purchase-street1-input" class="choiry-input" #street1 required placeholder="Ulica" 
                        (input)="street1Validity=validationService.validateStreet1(street1.value,true)" />
                    <div *ngIf="street1Validity!=''" class="flex gap-2 h-6 text-error-content items-center">
                        <span class="icon-24 w-6 h-6">warning</span>
                        <p class="font-label" [innerHTML]="street1Validity"></p>
                    </div>
                </div>

                <div class="flex flex-col gap-2">
                    <input type="text" id="purchase-street2-input" class="choiry-input" #street2 required placeholder="Numer Budynku" 
                        (input)="street2Validity=validationService.validateStreet2(street2.value,true)" />
                    <div *ngIf="street2Validity!=''" class="flex gap-2 h-6 text-error-content items-center">
                        <span class="icon-24 w-6 h-6">warning</span>
                        <p class="font-label" [innerHTML]="street2Validity"></p>
                    </div>
                </div>

                <div class="flex flex-col gap-2">
                    <input type="text" id="purchase-street3-input" class="choiry-input" #street3 required placeholder="Numer Mieszkania" 
                        (input)="street3Validity=validationService.validateStreet3(street3.value,false)" />
                    <div *ngIf="street3Validity!=''" class="flex gap-2 h-6 text-error-content items-center">
                        <span class="icon-24 w-6 h-6">warning</span>
                        <p class="font-label" [innerHTML]="street3Validity"></p>
                    </div>
                </div>

                <div class="flex flex-col gap-2">
                    <input type="text" id="purchase-city-input" class="choiry-input" #city required placeholder="Miasto" 
                        (input)="cityValidity=validationService.validateCity(city.value,true)" />
                    <div *ngIf="cityValidity!=''" class="flex gap-2 h-6 text-error-content items-center">
                        <span class="icon-24 w-6 h-6">warning</span>
                        <p class="font-label" [innerHTML]="cityValidity"></p>
                    </div>
                </div>

                <div class="flex flex-col gap-2">
                    <input type="text" id="purchase-postcode-input" class="choiry-input" #postcode required placeholder="Kod pocztowy" 
                        (input)="postCodeValidity=validationService.validatePostCode(postcode.value,true)" />
                    <div *ngIf="postCodeValidity!=''" class="flex gap-2 h-6 text-error-content items-center">
                        <span class="icon-24 w-6 h-6">warning</span>
                        <p class="font-label" [innerHTML]="postCodeValidity"></p>
                    </div>
                </div>
            </div>

            <div class="flex flex-col gap-4 font-label">
                <label class="flex gap-4">
                    <input type="checkbox" id="purchase-email-consent" name="emailConsent"/>
                    <span class="text-ch-grey-500">
                        <span class="text-error-content">*</span> 
                        Zgadzam się na przesłanie faktury w formie elektronicznej na wskazany adress email.
                    </span>
                </label>
                <label class="flex gap-4">
                    <input type="checkbox" id="purchase-przelewy24-consent" name="purchase-przelewy24Consent"/>
                    <span class="text-ch-grey-500">
                        <span class="text-error-content">*</span> 
                        Oświadczam, że zapoznałem się z <a href="https://www.przelewy24.pl/regulamin" target="_blank">regulaminem</a> i <a href="https://www.przelewy24.pl/obowiazek-informacyjny-platnik" target="_blank">obowiązkiem informacyjnym</a> serwisu Przelewy24.
                    </span>
                </label>
            </div>
            <div *ngIf="enableSummaryBtn" class="ch-btn ch-btn-filled ch-btn-sec" (click)="createPurchaseOrder()">Podsumowanie</div>
            <div *ngIf="!enableSummaryBtn"><i class="icon icon-48 animate-spin">progress_activity</i></div>
        </div>
    </div>
</div>
import { Component, OnInit} from '@angular/core';
import { SubscriptionCode } from '@app/_models';
import { AlertService} from '@app/_services';
import { SubscriptionCodeService } from '@app/_services/subscriptioncode.service';
import { ValidationService } from '@app/_services/validation.service';
import { Account } from '@app/authserver/_models';
import { AccountService } from '@app/authserver/_services';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
})
export class SubscriptionComponent implements OnInit {
    userAccount: Account;
    checkedCode: boolean = false;
    isCodeValid: boolean = false;
    validUntilString: string = '';
    enteredCode: SubscriptionCode;
    activePlan: string;
    maxStorage: number;
    usedStorageDescription: string;

    constructor(private alertService: AlertService,
      private subscriptionCodeService: SubscriptionCodeService,
      private accountService: AccountService,
      public validationService: ValidationService) { 
        this.accountService.account.subscribe(x => {
          this.userAccount = x;
          this.activePlan = this.accountService.activePlan();
          if(this.activePlan==="FREE"){
            this.maxStorage = 25 * 1024 * 1024;
          }
          if(this.activePlan==="STANDARD"){
            this.maxStorage = 250 *1024 * 1024;
          }
          if(this.activePlan==="PRO"){
            this.maxStorage = 5 * 1024 * 1024 * 1024;
          }
        });
      }

    ngOnInit() {
      document.getElementById("subscription-info").innerHTML=this.accountService.subscriptionInfo();
      this.usedStorageDescription = Math.round((this.userAccount.usedSpace/1024)/102.4)/10+"MB / "+Math.round((this.maxStorage/1024)/102.4)/10+"MB"
    }

    activateSubscriptionCode(code: string){
      this.subscriptionCodeService.activateSubscriptionCode(code).subscribe(result =>{
        this.alertService.info("Aktywowano subskrypcję.");
      },
        err => {
          this.alertService.error("Nie można aktywować tego kodu. Skontaktuj się z administratorem.");
        });
    }

    checkCode(code: string){
      this.checkedCode=false;
      if(code.length==10){
        this.subscriptionCodeService.checkSubscriptionCode(code).subscribe(result =>{
          this.isCodeValid = true;
          this.validUntilString = new Date(result.validUntilDate).toLocaleTimeString() + " " + new Date(result.validUntilDate).toLocaleDateString();
          this.enteredCode = result;
        },err => {this.isCodeValid=false;});
        this.checkedCode=true;
      }
    }
}

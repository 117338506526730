<div class="relative w-10 h-10" (click)="showMenu=true" (clickOutside)="showMenu=false">
    <div class="w-10 h-10 bg-ch-blue-50 rounded-full p-1 cursor-pointer">
        <div class="icon-32 icon text-primary">notifications</div>
        <div *ngIf="attention" class="absolute left-[23.6px] top-[6.8px] w-[11.2px] h-[11.2px] rounded-full border-[2px] border-ch-blue-50 bg-ch-red-500"></div>
    </div>
    <div *ngIf="showMenu" class="w-[463px] rounded-[10px] flex flex-col bg-white shadow-sm absolute top-10 right-0">
        <div class="px-8 py-6 flex flex-col gap-[10px]">
            <div class="font-title">Powiadomienia</div>
            <div class="flex gap-1 text-primary cursor-pointer items-center" (click)="markReadAll()">
                <div class="icon icon-16">check</div>
                <div class="font-boldbody">Odznacz wszystkie jako przeczytane</div>

            </div>
        </div>
        <div class="flex flex-col max-h-[545px] overflow-y-scroll">
            <div *ngFor="let notification of notifications" class="border-t-[1px] border-ch-grey-50 px-8 py-6" [ngClass]="notification.viewed? 'bg-white':'bg-ch-blue-50 cursor-pointer'" (click)="markRead(notification)">
                <div *ngIf="notification.type==='PLAYLIST_SHARE'" class="flex justify-between items-center">
                    <div class="flex gap-4 items-center">
                        <avatar *ngIf="notification.generatedByUser && notification.generatedByUser.id" type="user-avatar" [avatarUrl]="notification.generatedByUser.avatarUrl" [userId]="notification.generatedByUser.id" [firstName]="notification.generatedByUser.firstName" [lastName]="notification.generatedByUser.lastName"></avatar>
                        <div *ngIf="!notification.generatedByUser">System</div>
                        <div class="font-label">
                            <span class="font-bold">{{notification.generatedByUser.firstName}} {{notification.generatedByUser.lastName}}</span> 
                            Udostępnił Ci swoją playlistę 
                            <span class="font-bold">{{notification.sharedPlaylistTitle}}</span>
                        </div>
                    </div>
                    <div class="font-label text-ch-grey-300">{{notification.timestr}}</div>
                </div>
                <div *ngIf="notification.type==='PIECE_SHARE'" class="flex justify-between items-center">
                    <div class="flex gap-4 items-center">
                        <avatar *ngIf="notification.generatedByUser && notification.generatedByUser.id" type="user-avatar" [avatarUrl]="notification.generatedByUser.avatarUrl" [userId]="notification.generatedByUser.id" [firstName]="notification.generatedByUser.firstName" [lastName]="notification.generatedByUser.lastName"></avatar>
                        <div class="font-label">
                            <span class="font-bold">{{notification.generatedByUser.firstName}} {{notification.generatedByUser.lastName}}</span> 
                            Udostępnił Ci swój utwór 
                            <span class="font-bold">{{notification.sharedPieceTitle}}</span>
                        </div>
                    </div>
                    <div class="font-label text-ch-grey-300">{{notification.timestr}}</div>
                </div>
                <div *ngIf="notification.type==='ENDING_SUBSCRIPTION'" class="flex flex-col gap-2">
                    <div class="flex justify-between items-center">
                        <div class="flex gap-4 items-center">
                            <img src="/assets/images/choiry/choiry-symbol.png" width="48px" height="48px"/>
                            <div class="font-label">
                                Za 
                                <span class="font-bold">{{notification.daysUntilSubscriptionEnds}} dni</span> 
                                kończy się Twoja subskrypcja, aby przedłużyć przejdź do 
                                <span class="font-bold">Płatności.</span>
                            </div>
                        </div>
                        <div class="font-label text-ch-grey-300">{{notification.timestr}}</div>
                    </div>
                    <div class="pl-16">
                        <div class="ch-btn-small ch-btn-filled ch-btn-pri" [routerLink]="'subscription'">Przejdź do płatności</div>
                    </div>
                </div>
                <div *ngIf="notification.type==='USERGROUP_INVITATION'" class="flex flex-col gap-2`">
                    <div class="flex justify-between items-center">
                        <div class="flex gap-4 items-center">
                            <avatar *ngIf="notification.generatedByUser && notification.generatedByUser.id" type="user-avatar" [avatarUrl]="notification.generatedByUser.avatarUrl" [userId]="notification.generatedByUser.id" [firstName]="notification.generatedByUser.firstName" [lastName]="notification.generatedByUser.lastName"></avatar>
                            <div class="font-label">
                                <span class="font-bold">{{notification.generatedByUser.firstName}} {{notification.generatedByUser.lastName}}</span> 
                                dodał(-a) Cię do grupy 
                                <span class="font-bold">{{notification.invitationUserGroupName}}</span>
                            </div>
                        </div>
                        <div class="font-label text-ch-grey-300">{{notification.timestr}}</div>
                    </div>
                    <!-- BUTTONS -->
                    <div *ngIf="!notification.accepted && !notification.declined" class="pl-16 flex gap-2">
                        <div class="ch-btn-small ch-btn-filled ch-btn-pri" (click)="acceptGroupInvitation(notification)">Akceptuj</div>
                        <div class="ch-btn-small ch-btn-outline ch-btn-pri" (click)="declineGroupInvitation(notification)">Odrzuć</div>
                    </div>
                    <div *ngIf="notification.accepted" class="pl-16">
                        <div class="font-boldbody text-ch-grey-800">Zaakceptowano</div>
                    </div>
                    <div *ngIf="notification.declined" class="pl-16">
                        <div class="font-boldbody text-ch-grey-800">Odrzucono</div>
                    </div>
                </div>
                <!-- TODO subscription confirmation -->
            </div>
            <div class="border-t-[1px] border-ch-grey-50 py-8 text-center w-full">
                <div class="underline text-ch-grey-500 font-boldbody ">Pokaż więcej</div>
            </div>
        </div>
    </div>
</div>
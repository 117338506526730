<div class="w-full flex gap-6">
  <app-menu></app-menu>
  <div class="flex-1">
    <div *ngIf="userGroup" class="bg-white py-12 flex flex-col gap-6 rounded-b-xl">
      <div class="h-16 bg-ch-grey-50 rounded-t-xl w-full flex py-4 px-8 items-center justify-between">
        <div class="rounded-full w-8 h-8 border-ch-grey-700 border-[1px] p-1"><div class="icon icon-24">arrow_left_alt</div></div>
        <div class="font-boldbody">Grupy</div>
        <div class="w-8 h-8"></div>
      </div>
      <div class="px-12 flex flex-col gap-4 ">
        <!-- HEADER -->
         <div class="flex gap-10 px-6 py-4 items-start">
          <div>
            <avatar *ngIf="isEditor" type="usergroup-avatar" [size]="'full'" [userGroup]="userGroup" [showEditBadge]="true"></avatar>
            <avatar *ngIf="!isEditor" type="usergroup-avatar" [size]="'full'" [userGroup]="userGroup"></avatar>
          </div>
          <div class="flex flex-col gap-2 flex-1">
            <div class="flex items-center gap-4">
              <div class="icon icon-24 text-primary">group</div>
              <div class="font-title">{{userGroup.name}}</div>
            </div>
            <div class="font-label">
              {{userGroup.description}}
            </div>
          </div>
          <div class="flex items-center gap-4">
            <avatar type="user-avatar" [size]="'small'"[avatarUrl]="userGroup.owner.avatarUrl" [userId]="userGroup.ownerId" [firstName]="userGroup.owner.firstName" [lastName]="userGroup.owner.lastName" [showCreationTooltip]="true" [tooltipDate]="userGroup.creationDate"></avatar>
            <div *ngIf="isEditor" class="icon icon-btn icon-24 text-primary" [routerLink]="['/edit/usergroup',userGroup.id]">border_color</div>
          </div>
        </div>
        <!-- ZAKŁADKI -->
        <div class="flex gap-4 h-10 w-full">
          <div class="flex-1 flex justify-between px-4 py-2 cursor-pointer rounded-[5px]" [ngClass]="showUsers?'bg-primary text-primary-content':'text-primary bg-ch-blue-50'" (click)="selectTab('users')">
            <div class="font-boldbody text-[12px]">Użytkownicy w grupie [{{userGroup.users.length}}]</div>
            <div *ngIf="showUsers" class="icon icon-24">expand_less</div>
            <div *ngIf="!showUsers" class="icon icon-24">expand_more</div>
          </div>
          <div class="flex-1 flex justify-between px-4 py-2 cursor-pointer rounded-[5px]" [ngClass]="showPlaylists?'bg-primary text-primary-content':'text-primary bg-ch-blue-50'" (click)="selectTab('playlists')">
            <div class="font-boldbody text-[12px]">Playlisty w grupie [{{playlists?playlists.length:'0'}}]</div>
            <div *ngIf="showPlaylists" class="icon icon-24">expand_less</div>
            <div *ngIf="!showPlaylists" class="icon icon-24">expand_more</div>
          </div>
          <div class="flex-1 flex justify-between px-4 py-2 cursor-pointer rounded-[5px]" [ngClass]="showPieces?'bg-primary text-primary-content':'text-primary bg-ch-blue-50'" (click)="selectTab('pieces')">
            <div class="font-boldbody text-[12px]">Utwory w grupie [{{pieces?pieces.length:'0'}}]</div>
            <div *ngIf="showPieces" class="icon icon-24">expand_less</div>
            <div *ngIf="!showPieces" class="icon icon-24">expand_more</div>
          </div>
        </div>
        <!-- LISTY -->
        <div *ngIf="showUsers && invitedUsers" class="py-6 bg-ch-blue-50 rounded-[5px]">
          <div class="flex flex-col gap-6 px-4">
            <!-- MEMBERS -->
            <div *ngFor="let user of userGroup.users" class="flex w-full justify-between items-center">
              <div class="flex gap-4">
                <avatar type="user-avatar" [avatarUrl]="user.avatarUrl" [userId]="user.id" [firstName]="user.firstName" [lastName]="user.lastName"></avatar>
                <div class="flex flex-col gap-2">
                  <div class="font-boldbody text-ch-grey-800">{{user.firstName}} {{user.lastName}}</div>
                  <div class="font-label text-ch-grey-600">{{user.username}}</div>
                </div>
              </div>
              <div class="icon icon-btn icon-24 text-primary" (click)="removeMemberFromUserGroup(user.id)">delete</div>
            </div>
            <!-- INVITATIONS PENDING -->
            <div *ngFor="let user of invitedUsers" class="flex w-full justify-between items-center">
              <div class="flex gap-4">
                <avatar type="user-avatar" [avatarUrl]="user.avatarUrl" [userId]="user.id" [firstName]="user.firstName" [lastName]="user.lastName"></avatar>
                <div class="flex flex-col gap-2">
                  <div class="font-boldbody text-ch-grey-800">{{user.firstName}} {{user.lastName}} (OCZEKUJĄCE ZAPROSZENIE)</div>
                  <div class="font-label text-ch-grey-600">{{user.username}}</div>
                </div>
              </div>
              <div class="ch-btn-small ch-btn-outline ch-btn-pri" (click)="revokeInvitation(user.id)">Wycofaj zaproszenie</div>
            </div>
          </div>
        </div>
        <div *ngIf="showPlaylists" class="py-2 bg-ch-blue-50 rounded-[5px]">
          <div class="flex flex-col px-4">
            <!-- LINK REORGANIZE -->
            <div *ngFor="let playlist of playlists" class="flex w-full justify-between items-center py-4 cursor-pointer" [routerLink]="['/playlist',playlist.id]">
              <div class="flex gap-4">
                <div class="icon icon-24 text-primary">playlist_play</div>
                <div>{{playlist.title}}</div>
              </div>
              <div class="icon icon-btn icon-24 text-primary">delete</div>
            </div>
          </div>
        </div>
        <div *ngIf="showPieces" class="py-2 bg-ch-blue-50 rounded-[5px]">
          <div class="flex flex-col px-4">
            <!-- LINK REORGANIZE -->
            <div *ngFor="let piece of pieces" class="flex w-full justify-between items-center py-4 cursor-pointer" [routerLink]="['/piece',piece.id]">
              <div class="flex gap-4">
                <div class="icon icon-24 text-primary">library_music</div>
                <div>{{piece.title}}</div>
              </div>
              <div class="icon icon-btn icon-24 text-primary">delete</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
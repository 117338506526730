import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AlertService, PlaylistService, SharingService } from '@app/_services';
import { Playlist } from '@app/_models';
import { Account } from '@app/authserver/_models';
import { AccountService } from '@app/authserver/_services';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-playlist',
  templateUrl: './playlist.component.html'
})
export class PlaylistComponent implements OnInit {
    id: number;
    linkKey: string | undefined;
    isSubscriptionActive: boolean=false;
    userAccount: Account;
    isEditor: boolean = false;
    selectedPiece: number = 0;
    selectedPieceTitle: string='';
    showList: boolean = true;
    playlist: Playlist;
    cantLoad: boolean = false;

    constructor(private router: Router,
      private route: ActivatedRoute,
      private playlistService: PlaylistService,
      private accountService: AccountService,
      private alertService: AlertService,
      private titleService: Title
      ) {
        this.accountService.account.subscribe(x => this.userAccount = x);
      }

    ngOnInit() {
      this.route.params.subscribe(params => {
        this.id = +params['id']; // (+) converts string 'id' to a number
        this.linkKey = params['key'];
        this.route.queryParams.subscribe(queryParams => {
          this.selectedPiece = +queryParams['piece'];
          if(Number.isNaN(this.selectedPiece)){
            this.selectedPiece=0;
          }
        })

        //load the details
        this.playlistService.getById(this.id,this.linkKey).subscribe(x => { 
          this.playlist = x; 
          this.isEditor= this.userAccount != null && this.userAccount.userId==x.ownerId;
          //TODO: set selectedPieceTitle
          this.titleService.setTitle(this.playlist.title); 
        },err => {this.cantLoad=true;});
      })
    }

    selectPiece(id: number,title: string){
      var queryParams: Params;
      if(id==this.selectedPiece) id=0;
      if(id != 0){
        queryParams = { piece: id };
        this.selectedPiece=id;
        this.selectedPieceTitle=title;
      }
      else{
        queryParams = { piece: 'none'};
        this.selectedPiece=0;
        this.selectedPieceTitle='';
        this.showList=true;
      }

      this.router.navigate([], {relativeTo: this.route, queryParams: queryParams, queryParamsHandling: 'merge', });
    }

    sortedPlaylistPieces(){
      return this.playlist.pieces.sort((a, b) => a.ordinalNumber > b.ordinalNumber ? 1 : a.ordinalNumber === b.ordinalNumber ? 0 : -1);
    }

    openModal(id: string) {
      document.getElementById(id).style.display = 'block';
      //document.body..    overflow: hidden;
    }

    updatePlaylist(){
      this.playlistService.update(this.playlist).subscribe();
    }

    readableDate(timestamp){
      let date = new Date(timestamp)
      return date.getDay()+"."+date.getMonth()+"."+date.getFullYear();
    }

}

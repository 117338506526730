import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SubscriptionPurchaseData } from '@app/_models/subscriptionpurchasedata';
import { AlertService, PurchaseService, ValidationService } from '@app/_services';
import { Account } from '@app/authserver/_models';
import { AccountService } from '@app/authserver/_services';

@Component({
  selector: 'app-purchase-new',
  templateUrl: './purchasenew.component.html',
})
export class PurchaseNewComponent implements OnInit {
    userAccount: Account;
    spd: SubscriptionPurchaseData;
    creatingOrder: boolean = false;
    enableSummaryBtn: boolean = true;
    entity: string = 'NATURALPERSON';
    productId: string;
    amount: string;


    firstNameValidity = '';
    lastNameValidity = '';
    companyNameValidity = '';
    taxIdValidity = '';
    street1Validity = '';
    street2Validity = '';
    street3Validity = '';
    cityValidity = '';
    postCodeValidity = '';
    emailValidity = '';

    constructor(
      private alertService: AlertService,
      private accountService: AccountService,
      private purchaseService: PurchaseService,
      public validationService: ValidationService,
      private router: Router) { 
        this.accountService.account.subscribe(x => this.userAccount = x);
        this.spd=new SubscriptionPurchaseData;
        this.spd.company=false;
        this.spd.naturalPerson=true;
        this.spd.firstName=this.userAccount.firstName;
        this.spd.lastName=this.userAccount.lastName;
        this.spd.contactEmail=this.userAccount.email;
      }

    ngOnInit() {
      this.selectProduct('SUBSCRIPTION_CHOIRY_30');
    }

    selectProduct(product: string){
      this.productId = product;
      if(this.productId=='SUBSCRIPTION_CHOIRY_30'){
        this.amount = "50 PLN";
      } else if(this.productId=='SUBSCRIPTION_CHOIRY_90'){
        this.amount = "100 PLN";
      }
    }

    selectEntity(type: string){
      this.entity = type;
      if(type=='NATURALPERSON'){
        // validate...
      } else if(type=='COMPANY'){
        // validate...
      }
    }

    createPurchaseOrder(){
      if(this.entity==='NATURALPERSON'){
        this.spd.company=false;
        this.spd.naturalPerson=true;
      } else if(this.entity==='COMPANY'){
        this.spd.company=true;
        this.spd.naturalPerson=false;
      } 

      if(this.spd.company){
        this.spd.companyName=(<HTMLInputElement>document.getElementById("purchase-company-name-input")).value;
        this.spd.taxID=(<HTMLInputElement>document.getElementById("purchase-taxid-input")).value;
      }
      if(this.spd.naturalPerson){
        this.spd.firstName=(<HTMLInputElement>document.getElementById("purchase-first-name-input")).value;
        this.spd.lastName=(<HTMLInputElement>document.getElementById("purchase-last-name-input")).value;
      }
      this.spd.productId=this.productId;
      this.spd.contactEmail=(<HTMLInputElement>document.getElementById("purchase-email-input")).value;
      this.spd.street1=(<HTMLInputElement>document.getElementById("purchase-street1-input")).value;
      this.spd.street2=(<HTMLInputElement>document.getElementById("purchase-street2-input")).value;
      this.spd.street3=(<HTMLInputElement>document.getElementById("purchase-street3-input")).value;
      this.spd.city=(<HTMLInputElement>document.getElementById("purchase-city-input")).value;
      this.spd.postCode=(<HTMLInputElement>document.getElementById("purchase-postcode-input")).value;
      this.spd.emailConsent=(<HTMLInputElement>document.getElementById('purchase-email-consent')).checked;
      this.spd.przelewy24Consent=(<HTMLInputElement>document.getElementById('purchase-przelewy24-consent')).checked;

      this.creatingOrder=true;
      if(this.validationService.validateSPD(this.spd)){
        this.enableSummaryBtn = false;
        this.purchaseService.create(this.spd).subscribe((x)=>{
          this.spd = x; 
          this.creatingOrder=false;
          if(this.spd.orderId != undefined && this.spd.orderId!=0)
            this.router.navigate([`/purchase/summary/${this.spd.orderId}`]);
          else
            {}//todo:show error
        });
      } else
        this.alertService.error("Sprawdź poprawność wprowadzonych danych");
    }

}

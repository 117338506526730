import { User } from "./user";

export class InAppNotification{
    id: number;
    creationDate: number;
    type: string;
    userId: string;
    accepted: boolean;
    declined: boolean;
    viewed: boolean;
    generatedByUser: User;
    //for usergroup invitation
    invitationUserGroupId: number;
    invitationUserGroupName: string;
    //for join request
    joinRequestUserGroupId: number;
    joinRequestUserGroupName: string;
    //for shares
    sharedPlaylistId: number;
    sharedPlaylistTitle: string;
    sharedPieceId: number;
    sharedPieceTitle: string;
    //end of subscription
    daysUntilSubscriptionEnds: number;
    //only for frontend
    timestr?: string;
}
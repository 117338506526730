import { Component, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { UserGroup, User } from '@app/_models';
import { UserGroupService, AlertService, ValidationService } from '@app/_services';
import { CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { AccountService, AuthServerService } from '@app/authserver/_services';

@Component({ templateUrl: 'editusergroup.component.html' })
export class EditUserGroupComponent implements OnInit {
    id: number;
    users: User[] =[];
    userGroup: UserGroup;
    lastUserListUpdate: number;
    isThrottled: boolean = false;
    sub: any;

    constructor(
        private route: ActivatedRoute,
        private userGroupService: UserGroupService,
        private alertService: AlertService,
        private authServerService: AuthServerService,
        public validationService: ValidationService,
    ) { }


    ngOnInit() {
        this.sub=this.route.params.subscribe(params => {
            this.id = +params['id']; // (+) converts string 'id' to a number
            this.userGroupService.getById(this.id).subscribe(x => { this.userGroup = x;});
          })

          this.lastUserListUpdate = (new Date()).getTime();
    }

    updateSearchResult(){
        var currentDate = new Date();
        var timebalance = this.lastUserListUpdate+3000-currentDate.getTime();
        if(this.isThrottled)
            return;
        this.isThrottled=true;
        if(timebalance<0)
            timebalance=0;
        setTimeout(() =>{
            console.log(this.isThrottled,timebalance)
            var query = (<HTMLInputElement>document.getElementById("searchuser")).value;
            if(query.length>2)
                this.authServerService.reqSearchUser(query).subscribe(x => { this.users = x; this.subtractSets(); this.isThrottled=false; this.lastUserListUpdate = currentDate.getTime();});
            else
                this.isThrottled=false;
        }, timebalance)
    }

    subtractSets(){
        if(this.users.length>0 && this.userGroup.users.length>0){
            this.users = this.users.filter(x => {var dup=0; this.userGroup.users.forEach(y => {if(x.id==y.id) dup+=1;}); return dup==0;})
        }
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
      }
    
    updateUserGroup(){
        var update = new UserGroup;
        update.id = this.userGroup.id;
        update.description=(<HTMLInputElement>document.getElementById("usergroup-description-input")).value;
        update.name=(<HTMLInputElement> document.getElementById("usergroup-name-input")).value;
        if(this.validationService.validateName(update.name) && this.validationService.validateDescription(update.description)){
            this.userGroupService.update(update).subscribe(() => this.alertService.success("User Group name and description updated"));
        } else {
            this.alertService.error("Check validity of input data!");
        }
    }

    addMemberToUserGroup(event: CdkDragDrop<User[]>){
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(event.previousContainer.data,event.container.data,event.previousIndex,event.currentIndex);
            this.userGroupService.inviteMember(this.userGroup.id,event.item.data.id).subscribe();
        }
    }
    removeMemberFromUserGroup(event: CdkDragDrop<User[]>){
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(event.previousContainer.data,event.container.data,event.previousIndex,event.currentIndex);
            this.userGroupService.removeUser(this.userGroup.id,event.item.data.id).subscribe();
        }
    }

}
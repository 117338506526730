<div class="w-full flex gap-6">
  <app-menu></app-menu>
  <div class="flex-1">
    <div class="bg-white pb-[56px] flex flex-col gap-12 rounded-b-xl">
      <div class="h-16 bg-ch-grey-50 rounded-t-xl w-full flex py-4 px-8 items-center justify-between">
        <div class="rounded-full w-8 h-8 border-ch-grey-700 border-[1px] p-1"><div class="icon icon-24">arrow_left_alt</div></div>
        <div class="font-boldbody">Grupy</div>
        <div class="w-8 h-8"></div>
      </div>
      <div class="flex flex-col gap-4 px-12">
        <div>
          <app-dropdownfilter [options]="[{key:'owned',name:'Zarządzane przez Ciebie'},{key:'belong',name:'Do których należysz'}]"  (onSelect)="setFilter($event)"></app-dropdownfilter>
        </div>
        <div>
          <div *ngFor="let userGroup of userGroups" class="flex justify-between items-center p-6 cursor-pointer hover:bg-ch-blue-50">
            <div class="flex gap-3 items-center" [routerLink]="['/usergroup',userGroup.id]">
              <div class="icon icon-24 text-primary">group</div>
              <div>{{userGroup.name}}</div>
            </div>
            <div class="flex gap-4">
              <div class="icon icon-btn icon-24 text-primary" [routerLink]="'/edit/usergroup/'+userGroup.id">border_color</div>
              <div class="icon icon-btn icon-24 text-primary" (click)="confirmDeleteUserGroup(userGroup)">delete</div>
            </div>
          </div>
          <div *ngIf="!userGroupCreation" class="flex justify-between pt-4">
            <div></div>
            <div class="ch-btn ch-btn-filled ch-btn-sec" (click)="userGroupCreation=true">Dodaj nową grupę</div>
          </div>
          <div *ngIf="userGroupCreation">
              <div class="px-6 bg-ch-yellow-50 flex gap-4 items-center h-[72px]">
                <div class="flex-1 flex gap-3 border-b-2 border-secondary">
                  <div class="icon icon-24 text-secondary">group</div>
                  <div class="flex-1"><input type="text" class="choiry-input !border-none" id="new-usergroup-name" (input)="validationService.updateName('new-usergroup-name');" value="" placeholder="Grupa" (keyup.enter)="createUserGroup()" /></div>
                </div>
                <div class="flex gap-4">
                  <div class="ch-btn ch-btn-filled ch-btn-sec" (click)="createUserGroup()">Zapisz</div>
                  <div class="ch-btn ch-btn-outline ch-btn-sec" (click)="userGroupCreation=false">Anuluj</div>
                </div>
              </div>
              <div class="ch-input-error text-error-content px-4" id="validation-feedback-new-usergroup-name"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-container #confirmationModalContainer></ng-container>
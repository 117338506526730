import { Component, OnInit } from '@angular/core';
import { AlertService, ValidationService } from '@app/_services';
import { AccountService } from '../_services/account.service';
import { Account } from '../_models/account';
import { RegisterForm } from '../_models/registerForm'
import { AuthServerService } from '../_services';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
})
export class RegisterComponent implements OnInit {
    registerFsm = 'form';
    firstNameValidity = '';
    lastNameValidity = '';
    emailValidity = '';
    passwordValidity = '';

    userAccount: Account;
    rf: RegisterForm;
    isRegistered: boolean = false;
    enableRegisterBtn: boolean = true;

    constructor(public validationService: ValidationService,
      private accountService: AccountService,
      private authServerService: AuthServerService,
      private alertService: AlertService) { 
        this.accountService.account.subscribe(x => this.userAccount = x);
        this.rf = new RegisterForm;
      }

    ngOnInit() {
    }

    register(){
      this.rf.firstName = (<HTMLInputElement>document.getElementById("register-first-name-input")).value;
      this.rf.lastName = (<HTMLInputElement>document.getElementById("register-last-name-input")).value;
      this.rf.email = (<HTMLInputElement>document.getElementById("register-email-input")).value;
      this.rf.password = (<HTMLInputElement>document.getElementById("register-password-input")).value;
      var passwordRepeat = (<HTMLInputElement>document.getElementById("register-password-repeat-input")).value;
      var TOUPPConsentInput = (<HTMLInputElement>document.getElementById("register-touppconsent-input"));
      var newsletterConsentInput = (<HTMLInputElement>document.getElementById("register-newsletterconsent-input"));
      this.rf.TOUPPConsent = TOUPPConsentInput.checked;
      this.rf.newsletterConsent = newsletterConsentInput.checked;

      this.firstNameValidity = this.validationService.validateFirstName(this.rf.firstName,true); 
      this.lastNameValidity = this.validationService.validateFirstName(this.rf.lastName,true);
      this.emailValidity = this.validationService.validateEmail(this.rf.email,true);
      this.passwordValidity = this.validationService.validatePassword(this.rf.password,passwordRepeat,true);

      if(this.firstNameValidity == '' &&
          this.lastNameValidity == '' &&
          this.emailValidity == '' &&
          this.passwordValidity == '' &&
          this.rf.TOUPPConsent){
        this.enableRegisterBtn=false;
        this.authServerService.reqRegister(this.rf).subscribe(()=>{
          this.isRegistered=true; 
          this.registerFsm='success';
        },
        ()=> {this.alertService.error("Nie można założyć konta. Być może ten adres email jest już zajęty");});
      } else {
        this.alertService.error("Zweryfikuj błędy formularza");
      }

    }
}

//changepass
//deleteuser
//logout
//newsletter sub confirm
//newsletter unsub
//newsletter send sub link
//update user
//avatar upload
//avatar delete